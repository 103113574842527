import { inject } from '@angular/core';
import { Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { first, filter, map } from 'rxjs/operators';

import { AuthApiService, UserService } from '@app/data/services';
import { AppStateService } from '@app/core/services/app-state.service';

export const AuthGuard: CanActivateFn = () => {
    const router = inject(Router);
    const authService = inject(AuthApiService);
    const appStateService = inject(AppStateService);

    if (authService.isAuthorized()) {
        // logged in so return true

        return appStateService.userInfo$.pipe(
            filter((userInfo) => {
                if (!userInfo?.user) {
                    if (authService.isAuthorized()) {
                        appStateService.getUserInfo();
                    }
                    return false;
                } else {
                    return true;
                }
            }),
            first(),
            map(() => true),
        );
    } else {
        // not logged in so redirect to login page
        return router.parseUrl('/login');
    }
};

export const LoginGuard: CanActivateFn = (_, state: RouterStateSnapshot) => {
    const authService = inject(AuthApiService);
    const userService = inject(UserService);
    const router = inject(Router);

    if (authService.isAuthorized()) {
        if (state.url.includes('select-silo') && !userService.isSiloExists()) {
            return true;
        }

        if (state.url.includes('accept-invite')) {
            return true;
        }

        if (!state.url.includes('app')) {
            return router.parseUrl('/app');
        }
    } else {
        if (state.url === '/') {
            return router.parseUrl('/login');
        }

        return true;
    }
};
